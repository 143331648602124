/**
  * Overriding styles for react-date-range picker component, for dark mode.
  * If this file needs to be updated, consult the original styles at
  *   /frontend/app/node_modules/react-date-range/dist/theme/default.css
  * and update these styles accordingly.
  */

.dateRangePicker {
  /* copied from /frontend/app/src/utils/style.ts */
  --DOPPEL_WHITE: #EEEFFB;
  --DOPPEL_LIGHT_GREY: #B1B1B1;
  --DOPPEL_DARK: #181921;
  --DOPPEL_DARK_GREY: #1E1E2A;
  --DOPPEL_DARK_SECONDARY: #20212D;
  --DOPPEL_DARK_CLICKABLE: #272938;
  --DOPPEL_DARK_CLICKABLE_HOVER: #2F3140;
  --DOPPEL_MAIN_BLUE_LIGHT_SHADE: #336AEA;

  /* variables for this file */
  --PRIMARY_BG: var(--DOPPEL_DARK_SECONDARY);
  --EXTERNAL_BG: var(--DOPPEL_DARK_GREY);
  --CLICKABLE: var(--DOPPEL_DARK_CLICKABLE);
  --CLICKABLE_HOVER: var(--DOPPEL_DARK_CLICKABLE_HOVER);
  --PRIMARY_TEXT: var(--DOPPEL_WHITE);
  --PASSIVE_TEXT: var(--DOPPEL_DARK_CLICKABLE_HOVER);
  --HIGHLIGHT: var(--DOPPEL_MAIN_BLUE_LIGHT_SHADE);
}

/* Calendar Wrapper */
.dateRangePicker :global(.rdrCalendarWrapper) {
  color: var(--PRIMARY_TEXT);
  background-color: var(--PRIMARY_BG);
}

/* Date Display */
.dateRangePicker :global(.rdrDateDisplayWrapper) {
  background-color: var(--EXTERNAL_BG);
}

.dateRangePicker :global(.rdrDateDisplayItem) {
  background-color: var(--CLICKABLE);
}

.dateRangePicker :global(.rdrDateDisplayItemActive) {
  border-color: var(--HIGHLIGHT);
}

.dateRangePicker :global(.rdrDateDisplayItem input) {
  color: var(--PRIMARY_TEXT);
}

.dateRangePicker :global(.rdrDateDisplayItem:hover) {
  background-color: var(--CLICKABLE_HOVER);
}

/* Month and Year Pickers */
.dateRangePicker :global(.rdrMonthAndYearPickers select) {
  color: var(--PRIMARY_TEXT);
  background-color: var(--CLICKABLE);
  background-image: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='white' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;
  appearance: none;
}

/* Days */
.dateRangePicker :global(.rdrDay) {
  background: var(--PRIMARY_BG);
  color: var(--PRIMARY_TEXT);
}

.dateRangePicker :global(.rdrDayNumber span) {
  color: var(--PRIMARY_TEXT);
}

/* Passive and Disabled Days */
.dateRangePicker :global(.rdrDayPassive .rdrDayNumber span) {
  color: var(--PASSIVE_TEXT);
}

.dateRangePicker :global(.rdrDayDisabled .rdrDayNumber span) {
  color: var(--PASSIVE_TEXT);
}


/* Today */
.dateRangePicker :global(.rdrDayToday .rdrDayNumber span) {
  font-weight: 500;
}

.dateRangePicker :global(.rdrDayToday .rdrDayNumber span:after) {
  background: var(--PRIMARY_TEXT);
}

/* Selected, In Range, Edges */
.dateRangePicker :global(.rdrSelected),
.dateRangePicker :global(.rdrInRange),
.dateRangePicker :global(.rdrStartEdge),
.dateRangePicker :global(.rdrEndEdge) {
  background: var(--HIGHLIGHT);
}

/* Hover */
.dateRangePicker :global(.rdrDayHovered .rdrDayNumber:after) {
  border: 1px solid var(--PRIMARY_TEXT);
}

/* Defined Ranges */
.dateRangePicker :global(.rdrDefinedRangesWrapper) {
  background: var(--PRIMARY_BG);
  border-right: solid 1px var(--PRIMARY_BG);
}

.dateRangePicker :global(.rdrStaticRange) {
  background: var(--CLICKABLE);
  border-bottom: 1px solid var(--PRIMARY_BG);
}

.dateRangePicker :global(.rdrStaticRange .rdrStaticRangeLabel) {
  color: var(--PRIMARY_TEXT);
}

.dateRangePicker :global(.rdrStaticRange:hover .rdrStaticRangeLabel),
.dateRangePicker :global(.rdrStaticRange:focus .rdrStaticRangeLabel) {
  background: var(--CLICKABLE_HOVER);
}

/* Month Name */
.dateRangePicker :global(.rdrMonthName) {
  color: var(--DOPPEL_LIGHT_GREY);
}

/* Next and Previous Buttons */
.dateRangePicker :global(.rdrNextPrevButton) {
  background: var(--CLICKABLE);
}

.dateRangePicker :global(.rdrNextPrevButton:hover) {
  background: var(--CLICKABLE_HOVER);
}

.dateRangePicker :global(.rdrPprevButton i) {
  border-color: transparent var(--DOPPEL_WHITE) transparent transparent;
}

.dateRangePicker :global(.rdrNextButton i) {
  border-color: transparent transparent transparent var(--DOPPEL_WHITE);
}

